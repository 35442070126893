import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  environmentName: 'quality',
  clarityProjectId: 'hx527bsmzg',
  hasura: {
    urlV1: 'https://jh3pxhpzpm.eu-west-1.awsapprunner.com/v1/graphql',
  },
  msalAuthConfig: {
    clientId: '44546c30-b15f-4b10-8e84-3f8c1f6b4ddb',
    authority: 'https://login.microsoftonline.com/95a44a6d-f922-49aa-b7e0-b37e0c0836c9',
    redirectUri: 'https://kit-qt.planetsmartcity.com',
    postLogoutRedirectUri: 'https://kit-qt.planetsmartcity.com',
    navigateToLoginRequestUrl: true,
  },
  msalScope: [
    'user.read',
    'openid',
    'profile',
    'email',
  ]
  // logRocketAppId: 'vfh4mt/kit-test',
  ,
  storageUrl: 'https://kit-qt-assets-storage-ff02f52.s3.eu-west-1.amazonaws.com/',
  commitHash: '195ae3ed266d50d3f9d6f2cacf604135e2894855',
  commitTag: 'null'
};
